<template>
    <a-spin :spinning="loading">
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span v-if="id">编辑App</span>
                <span v-else>添加App</span>
            </div>
        </div>
        <a-row :gutter="8">
            <a-row>
                <a-col :span="4">
                    <q-label required label="编码"> </q-label>
                </a-col>
                <a-col :span="8">
                    <a-input placeholder="请输入编码" v-model="entity.code" />
                </a-col>
                <a-col :span="4">
                    <q-label required label="名称"> </q-label>
                </a-col>
                <a-col :span="8">
                    <a-input placeholder="请输入名称" v-model="entity.name" />
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="4">
                    <q-label  label="描述"> </q-label>
                </a-col>
                <a-col :span="20">
                    <a-textarea placeholder="请输入描述" allow-clear v-model="entity.describe"  />
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="4">
                    <q-label  label="备注"> </q-label>
                </a-col>
                <a-col :span="20">
                    <a-textarea placeholder="请输入备注" allow-clear v-model="entity.remarks"  />
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="10" offset="2">
                    <a-checkbox v-model="entity.isIsShowUpdateDioLog">
                        是否显示更新弹窗
                    </a-checkbox>
                </a-col>
                <a-col :span="12">
                    <a-checkbox v-model="entity.isForceUpdate">
                        是否强制更新到最新版本
                    </a-checkbox>
                </a-col>
            </a-row>
        </a-row>
        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save()">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {AppBaseEditDto, AppBaseServiceProxy, CreateOrUpdateAppBaseInput} from "../../../../shared/service-proxies";

    export default {
        name: "create-or-edit-app-base",
        mixins: [ModalComponentBase],
        data(){
            return{
                id:undefined,
                entity:new AppBaseEditDto()
            }
        },
        created() {
            this.fullData();
            this._appBaseServiceProxy=new AppBaseServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if(this.id){
                this.getData();
            }
        },
        methods:{
            getData(){
                this.loading=true;
                this._appBaseServiceProxy.getForEdit(this.id)
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this.entity=res.appBase;
                })
            },
            save(){
                if(!this.entity.code){
                    return abp.message.warn("编码不能为空");
                }
                if(!this.entity.name){
                    return  abp.message.warn("名称不能为空")
                }
                this.loading=true;
                let input = new CreateOrUpdateAppBaseInput();
                input.appBase=this.entity;
                this._appBaseServiceProxy.createOrUpdate(input)
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this.$notification['success']({
                        message: this.l('SavedSuccessfully'),
                    });
                    this.success(true);
                })
            }
        }
    }
</script>

<style scoped>

</style>
