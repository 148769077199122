<template>
    <div ref="qrcode" style="margin: auto;padding: 10px">

    </div>
</template>

<script>

    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import QRCode from "qrcodejs2"

    export default {
        name: "app-qr-view",
        mixins: [ModalComponentBase],
        components:{QRCode},
        data(){
            return{
                filePath:undefined
            }
        },
        created() {
            this.fullData();
        },
        mounted() {
            this.$nextTick(()=>{
                this.getData();
            });
        },
        methods:{
            getData(){
                new QRCode(this.$refs.qrcode, {
                    text: this.filePath,
                    width: 200,
                    height: 200,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                })
            }
        }
    }
</script>

<style scoped>

</style>
