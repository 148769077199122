<template>
    <a-spin :spinning="loading">
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span v-if="id">编辑App明细</span>
                <span v-else>添加App明细</span>
            </div>
        </div>
        <a-row>
            <a-col :span="4">
                <q-label label="文件"></q-label>
            </a-col>
            <a-col :span="8">
                <a-upload
                        v-if="isShowUpload"
                        :action="uploadApkUrl"
                        :beforeUpload="beforeUpload"
                        :headers="uploadHeaders"
                        @change="uploadPictureChange($event)">
                    <a-button type="primary">
                        <a-icon type="import"/>
                        上传APK
                    </a-button>
                </a-upload>
                <a @click="download"  v-else>{{file}}</a>
            </a-col>
            <a-col :span="4" v-if="entity.fileSize">
                <q-label label="文件大小"></q-label>
            </a-col>
            <a-col :span="8" v-if="entity.fileSize">
                {{entity.fileSize?(entity.fileSize/1024/1024).toFixed(2)+"MB":""}}
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="4">
                <q-label required label="版本号"></q-label>
            </a-col>
            <a-col :span="8">
                <div v-if="id">{{entity.version}}</div>
                <a-input placeholder="请输入版本号" v-model="entity.version" v-else @blur="fileBlur" />
            </a-col>
            <a-col :span="4">
                <q-label required label="名称"></q-label>
            </a-col>
            <a-col :span="8">
                <div v-if="id">{{entity.fileName}}</div>
                <a-input placeholder="请输入名称" v-model="entity.fileName" v-else  />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="4">
                <q-label label="版本序号"></q-label>
            </a-col>
            <a-col :span="20">
                <a-input placeholder="版本序号" v-model="entity.versionSort"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="4">
                <q-label label="OSS地址"></q-label>
            </a-col>
            <a-col :span="20">
                <a-input placeholder="请输入OSS地址" v-model="entity.fileNameOSS"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="4">
                <q-label label="更新内容"></q-label>
            </a-col>
            <a-col :span="20">
                <a-textarea placeholder="弹出提醒用户升级时可见" allow-clear v-model="entity.content"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="4">
                <q-label label="备注"></q-label>
            </a-col>
            <a-col :span="20">
                <a-textarea placeholder="请输入备注" allow-clear v-model="entity.describe"/>
            </a-col>
        </a-row>

        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close()" type="button">
                <a-icon type="close-circle"/>
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save()">
                <a-icon type="save"/>
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {
        AppVersionDetailEditDto,
        AppVersionDetailServiceProxy,
        CreateOrUpdateAppVersionDetailInput
    } from "../../../../shared/service-proxies";
    import {AppConsts} from "../../../../abpPro/AppConsts";

    export default {
        name: "create-or-edit-app-detail",
        mixins: [ModalComponentBase],
        data() {
            return {
                id: undefined,
                appBaseId: undefined,
                entity: new AppVersionDetailEditDto(),
                uploadApkUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadAppApkFile",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    appCode:"default"
                },
                appCode:undefined,
                apkFilePath: `${AppConsts.remoteServiceBaseUrl}/File/AppApk/`,
                tempFilePath:`${AppConsts.remoteServiceBaseUrl}/File/Temp/Host/`,
                isShowUpload:true,
                uploadFlieName:undefined,
                file:""
            }
        },
        created() {
            this.fullData();
            this._appVersionDetailServiceProxy = new AppVersionDetailServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            if(this.appCode){
                this.uploadHeaders.appCode=this.appCode;
                this.apkFilePath=this.apkFilePath+this.uploadHeaders.appCode+"/";
                this.entity.appCode=this.appCode;
            }
            if (this.id) {
                this.getData();
            } else {
                this.entity.versionSort = await this.getVersionSort();
            }
        },
        methods: {
            getData() {
                this.loading = true;
                this._appVersionDetailServiceProxy.getForEdit(this.id)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.entity = res.appVersionDetail;
                        this.file=this.entity.fileName?this.entity.fileName:this.entity.uploadFlieName;
                        if(this.entity.fileSize){
                            this.isShowUpload=false;
                        }
                        this.appBaseId = res.appVersionDetail.appBaseId;
                    });
            },
            /**
             * 版本号失去光标事件
             */
            fileBlur(){
                if(this.entity.version){
                    this.entity.fileName=this.appCode+"_"+this.entity.version+".apk";
                }
            },
            /**
             *获取最新的版本序号
             * @return {Promise<unknown>}
             */
            getVersionSort() {
                return new Promise(resolve => {
                    this.loading = true;
                    this._appVersionDetailServiceProxy.getVersionSort(this.appBaseId)
                        .finally(() => {
                            this.loading = false;
                        })
                        .then(res => {
                            resolve(res);
                        });
                });
            },
            /**
             * 下载
             */
            download() {
                console.log(this.tempFilePath + this.entity.uploadFlieName)
                let url="";
                if(this.id){
                    url = this.apkFilePath + this.entity.fileName;
                }
                else {
                    url = this.tempFilePath + this.entity.uploadFlieName;
                }
                location.href = url;
                // window.open(url);
            },
            beforeUpload(file) {
                let filetype;
                if (file.name) {
                    filetype = file.name.substring(file.name.lastIndexOf('.'));
                }
                let isExcel = filetype === ".apk";
                if (!isExcel) {
                    return abp.message.warn("只能上传apk的文件");
                }
            },
            uploadPictureChange({file, fileList}) {
                if (file.status === 'done') {
                    this.file=file.name;
                    this.entity.uploadFlieName = file.response.result.fileName;
                    this.entity.fileSize = file.response.result.fileSize;
                    this.isShowUpload=false;
                }
            },
            save() {
                if (!this.entity.fileName) {
                    return abp.message.warn("文件名称不能为空");
                }
                if (!this.entity.version) {
                    return abp.message.warn("版本号不能为空");
                }
                this.entity.appBaseId = this.appBaseId;
                let input = new CreateOrUpdateAppVersionDetailInput();
                input.appVersionDetail = this.entity;
                this.loading = true;
                this._appVersionDetailServiceProxy.createOrUpdate(input)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.$notification['success']({
                            message: this.l('SavedSuccessfully'),
                        });
                        this.success(true);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
