<template>
    <a-config-provider :locale="zh_CN">
        <a-row>
            <a-col :span="10">
                <a-spin :spinning="apploading">
                    <a-card>
                        <a-row :gutter="[8,8]">
                            <a-col>
                                <a-button
                                        :type="'primary'"
                                        @click="createApp"
                                >
                                    <a-icon type="plus"/>
                                    <span>{{ l("Create") }}</span>
                                </a-button>
                                <a-button
                                        :type="'primary'"
                                        @click="editApp"
                                >
                                    <a-icon type="edit"/>
                                    <span>{{ l("Edit") }}</span>
                                </a-button>
                                <a-button
                                        :type="'danger'"
                                        @click="batchDelete"
                                >
                                    <a-icon type="delete"/>
                                    <span>{{ l("Delete") }}</span>
                                </a-button>
                                <a-button @click="qrcodeView">
                                    <a-icon type="qrcode"/>
                                    二维码
                                </a-button>
                                <a-input-search
                                        style="width: 200px;padding-left: 10px;"
                                        name="filterText"
                                        :placeholder="l('SearchWithThreeDot')"
                                        @search="appSearchChange"
                                        enterButton
                                        v-model="filterText"
                                        v-decorator="['filterText']"
                                />
                            </a-col>
                        </a-row>
                        <a-row :gutter="[8,8]">
                            <a-table
                                    class="list-table"
                                    :pagination="false"
                                    :customRow="customRow"
                                    :columns="columns"
                                    :rowKey="tableDatas => tableDatas.id"
                                    :dataSource="tableData">
                                <a-checkbox slot="checkbox"
                                            slot-scope="text, record"
                                            :checked="record.checked">
                                </a-checkbox>
                                <span slot="name" slot-scope="text,record">
                                    <a :href="apkFilePath+appCode+'/'+record.fileName" v-if="record.fileName">{{record.name}}</a>
                                    <a v-else>{{record.name}}</a>
                                </span>
                                <a class="isForceUpdate" slot="isForceUpdate" slot-scope="record">
                                    <a-icon v-if="record" type="check"/>
                                    <!--                                    <a-icon v-if="!record" type="close"/>-->
                                </a>
                            </a-table>
                            <a-pagination
                                    class="pagination"
                                    :total="totalItems"
                                    :showTotal="showTotalFun"
                                    v-model="pageNumber"
                                    @change="onChange"
                                    @showSizeChange="showSizeChange"
                            />
                        </a-row>
                    </a-card>
                </a-spin>
            </a-col>
            <a-col :span="14" style="padding-left: 5px">
                <a-spin :spinning="detailloading">
                    <a-card>
                        <a-row :gutter="[8,8]">
                            <a-col>
                                <a-button
                                        :type="'primary'"
                                        @click="createDetail"
                                >
                                    <a-icon type="plus"/>
                                    <span>{{ l("Create") }}</span>
                                </a-button>
                                <a-button
                                        :type="'primary'"
                                        @click="editDetail"
                                >
                                    <a-icon type="edit"/>
                                    <span>{{ l("Edit") }}</span>
                                </a-button>
                                <a-button
                                        :type="'danger'"
                                        @click="deleteDetail"
                                >
                                    <a-icon type="delete"/>
                                    <span>{{ l("Delete") }}</span>
                                </a-button>
                                <a-button @click="detailQrcodeView">
                                    <a-icon type="qrcode"/>
                                    二维码
                                </a-button>
                                <a-input-search
                                        style="width: 200px;padding-left: 10px;"
                                        name="filterText"
                                        :placeholder="l('SearchWithThreeDot')"
                                        @search="detailSearchChange"
                                        enterButton
                                        v-model="filterText2"
                                        v-decorator="['filterText']"
                                />
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-table
                                    @change="sorterChange2"
                                    class="list-table"
                                    :pagination="false"
                                    :customRow="customRow2"
                                    :columns="columns2"
                                    :rowKey="tableDatas => tableDatas.id"
                                    :dataSource="tableData2">
                                <a-checkbox slot="checkbox"
                                            slot-scope="text, record"
                                            :checked="record.checked">
                                </a-checkbox>
                                <span slot="version" slot-scope="text,record">
                                    <a :href="apkFilePath+appCode+'/'+record.fileName" v-if="record.fileName && record.fileSize">{{record.version}}</a>
                                    <a v-else>{{record.version}}</a>
                                </span>
                                <span slot="fileNameOSS" slot-scope="text,record">
                                    <a :href="record.fileNameOSS" v-if="record.fileNameOSS">下载</a>
                                </span>
                                <span slot-scope="text,record" slot="fileSize">
                                    {{record.fileSize?(record.fileSize/1024/1024).toFixed(2)+"MB":""}}
                                </span>


                            </a-table>
                            <a-pagination
                                    class="pagination"
                                    :total="totalItems2"
                                    :showTotal="showTotalFun2"
                                    v-model="pageNumber2"
                                    @change="onChange2"
                                    @showSizeChange="showSizeChange2"
                            />
                        </a-row>
                    </a-card>
                </a-spin>
            </a-col>
        </a-row>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import {AppBaseServiceProxy, AppVersionDetailServiceProxy} from "../../../shared/service-proxies";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN";
    import modalHelper from "../../../shared/helpers/modal/modal-helper";
    import CreateOrEditAppBase from "./create-or-edit-app-base/create-or-edit-app-base";
    import CreateOrEditAppDetail from "./create-or-edit-app-detail/create-or-edit-app-detail";
    import moment from "moment"
    import {AppConsts} from "../../../abpPro/AppConsts";
    import AppQrView from "./app-qr-view/app-qr-view";

    let _this;
    export default {
        name: "app-update-manage",
        mixins: [AppComponentBase],
        components: {CreateOrEditAppBase, CreateOrEditAppDetail},
        data() {
            return {
                zh_CN,
                apploading: false,
                detailloading: false,
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                filterText: undefined,
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40", "50"],
                request: {sorting: "", maxResultCount: 10, skipCount: 0},
                columns: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "name",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "name"}
                    },
                    {
                        title: this.l("最新版本"),
                        dataIndex: "version",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "version"}
                    },
                    {
                        title: this.l("最后更新时间"),
                        dataIndex: "createTime",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "createTime"}
                    },
                    {
                        title: this.l("强制更新"),
                        dataIndex: "isForceUpdate",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "isForceUpdate"}
                    }
                ],
                tableData: [],
                selectedAppRow: undefined,
                appbaseId: undefined,
                // 总数
                totalItems2: 0,
                // 当前页码
                pageNumber2: 1,
                // 共多少页
                totalPages2: 1,
                // 条数显示范围
                pagerange2: [1, 1],
                filterText2: undefined,
                // 显示条数
                pageSizeOptions2: ["10", "20", "30", "40", "50"],
                request2: {sorting: "", maxResultCount: 10, skipCount: 0},
                columns2: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("版本序号"),
                        dataIndex: "versionSort",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "versionSort"}
                    },
                    {
                        title: this.l("版本"),
                        dataIndex: "version",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "version"}
                    },
                    {
                        title: this.l("OSS地址"),
                        dataIndex: "fileNameOSS",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "fileNameOSS"}
                    },
                    {
                        title: this.l("提交时间"),
                        dataIndex: "creationTime",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "creationTime"}
                    },
                    {
                        title: this.l("文件大小"),
                        dataIndex: "fileSize",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "fileSize"}
                    }
                ],
                tableData2: [],
                selectedDetailRow: undefined,
                apkFilePath: `${AppConsts.remoteServiceBaseUrl}/File/AppApk/`,
                appCode:undefined
            }
        },
        created() {
            _this = this;
            this._appBaseServiceProxy = new AppBaseServiceProxy(this.$apiUrl, this.$api);
            this._appVersionDetailServiceProxy = new AppVersionDetailServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        methods: {
            /**
             * App刷新
             */
            appSearchChange() {
                this.request.skipCount = 0;
                this.pageNumber = 1;
                this.getData();
            },
            /**
             * 明细刷新
             */
            detailSearchChange() {
                this.request2.skipCount = 0;
                this.pageNumber2 = 1;
                this.getAppDetailData();
            },
            /**
             * 获取APP列表
             */
            getData() {
                this.apploading = true;
                this._appBaseServiceProxy.getPaged(
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                    .finally(() => {
                        this.apploading = false;
                    })
                    .then(res => {
                        this.tableData = res.items;
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(
                            res.totalCount / this.request.maxResultCount
                        );
                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount,
                        ];
                        this.tableData.map(item => {
                            item.createTime = item.createTime ? moment(item.createTime).format("yyyy-MM-DD HH:ss") : "";
                        });
                        if (this.tableData.length > 0) {
                            this.select(this.tableData[0]);
                        }
                    });
            },
            /**
             * App模拟选择
             */
            select(rows) {
                let row = this.tableData.find(item => item.id == rows.id);
                this.appbaseId = rows.id;
                this.appCode=rows.code;
                this.selectedAppRow = rows;
                row.checked = true;
                this.getAppDetailData();
                _this.$forceUpdate();
            },
            /**
             *APP列表点击事件
             */
            customRow(record, index) {
                return {
                    on: {
                        click: () => {
                            _this.appbaseId = record.id;
                            _this.appCode=record.code;
                            //设置当前点击的数据
                            _this.selectedAppRow = record;
                            //设置多选
                            //_this.selectedRowKeys = [record.id];
                            //清空选择
                            _this.tableData.map(item => item.checked = false);
                            //赋值选择
                            record.checked = !record.checked;
                            this.getAppDetailData();
                        },
                    },
                };
            },
            /**
             * App查看二维码
             */
            qrcodeView() {
                if (!this.selectedAppRow) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                let filePath = this.apkFilePath + this.selectedAppRow.fileName;
                if (this.selectedAppRow.fileNameOSS) {
                    filePath = this.selectedAppRow.fileNameOSS;
                }
                modalHelper.create(
                    AppQrView,
                    {
                        filePath: filePath
                    },
                    {
                        width: "280px"
                    }
                )
                    .subscribe(res => {

                    });
            },
            /**
             * 添加App
             */
            createApp() {
                modalHelper.create(CreateOrEditAppBase, {}, {
                    width: "600px"
                })
                    .subscribe(res => {
                        if (res) {
                            this.appSearchChange();
                        }
                    })
            },
            /**
             * 编辑App
             */
            editApp() {
                if (!this.appbaseId) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                modalHelper.create(CreateOrEditAppBase, {
                    id: this.appbaseId
                }, {
                    width: "600px"
                })
                    .subscribe(res => {
                        if (res) {
                            this.appSearchChange();
                        }
                    });
            },
            /**
             * 删除App
             */
            batchDelete() {
                if (!this.appbaseId) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                this.$confirm({
                    title: "操作确认",
                    content: "你确定要删除当前选中项吗？",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.loading = true;
                        this._appBaseServiceProxy.delete(this.appbaseId)
                            .finally(() => {
                                this.loading = false;
                            })
                            .then(res => {
                                this.$notification['success']({
                                    message: this.l('SuccessfullyDeleted'),
                                });
                                this.appSearchChange();
                            });
                    }
                });

            },
            getAppDetailData() {
                this.detailloading = true;
                this._appVersionDetailServiceProxy.getPaged(
                    this.appbaseId,
                    this.filterText2,
                    this.request2.sorting,
                    this.request2.maxResultCount,
                    this.request2.skipCount
                )
                    .finally(() => {
                        this.detailloading = false;
                    })
                    .then(res => {
                        this.tableData2 = res.items;
                        this.totalItems2 = res.totalCount;
                        this.totalPages2 = Math.ceil(
                            res.totalCount / this.request.maxResultCount
                        );
                        this.pagerange2 = [
                            (this.pageNumber2 - 1) * this.request2.maxResultCount + 1,
                            this.pageNumber2 * this.request2.maxResultCount,
                        ];
                        this.tableData2.map(item => {
                            item.creationTime = item.creationTime ? moment(item.creationTime).format("yyyy-MM-DD HH:ss") : "";
                        });
                    });
            },
            /**
             * 明细列表点击事件
             */
            customRow2(record, index) {
                return {
                    on: {
                        click: () => {
                            //设置当前点击的数据
                            _this.selectedDetailRow = record;
                            //清空选择
                            _this.tableData2.map(item => item.checked = false);
                            //赋值选择
                            record.checked = !record.checked;
                            _this.$forceUpdate();
                        },
                    },
                };
            },
            /**
             * 添加App明细
             */
            createDetail() {
                if (!this.appbaseId) {
                    return abp.message.warn("请至少选择一项APP,才能进行添加操作")
                }
                modalHelper.create(CreateOrEditAppDetail, {
                    appBaseId: this.appbaseId,
                    appCode:this.appCode
                }, {
                    width: "800px"
                })
                    .subscribe(res => {
                        if (res) {
                            this.appSearchChange();
                        }
                    });
            },
            /**
             * 编辑App明细
             */
            editDetail() {
                if (!this.selectedDetailRow) {
                    return abp.message.warn("请至少选择一项进行操作")
                }
                modalHelper.create(
                    CreateOrEditAppDetail,
                    {
                        id: this.selectedDetailRow.id,
                        appBaseId: this.appbaseId,
                        appCode:this.appCode
                    },
                    {
                        width: "800px"
                    }
                ).subscribe(res => {
                    if (res) {
                        this.appSearchChange();
                    }
                });
            },
            /**
             * 明细查看二维码
             */
            detailQrcodeView() {
                if (!this.selectedDetailRow) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                let filePath = this.apkFilePath + this.selectedDetailRow.fileName;
                if (this.selectedDetailRow.fileNameOSS) {
                    filePath = this.selectedDetailRow.fileNameOSS;
                }
                modalHelper.create(
                    AppQrView,
                    {
                        filePath: filePath
                    },
                    {
                        width: "280px"
                    }
                )
                    .subscribe(res => {

                    });
            },
            /**
             * 删除App明细
             */
            deleteDetail() {
                if (!this.selectedDetailRow) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                this.$confirm({
                    title: "操作确认",
                    content: "你确定要删除当前选中项吗？",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.loading = true;
                        this._appVersionDetailServiceProxy.delete(this.selectedDetailRow.id)
                            .finally(() => {
                                this.loading = false;
                            })
                            .then(res => {
                                this.$notification['success']({
                                    message: this.l('SuccessfullyDeleted'),
                                });
                                this.appSearchChange();
                            });
                    }
                });
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return `共有${this.totalItems}条`;
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },

            /**
             * 分页事件
             */
            showTotalFun2() {
                return `${this.pageNumber2}/${Math.ceil(this.totalItems2 / this.request2.maxResultCount) || 1}页，共${this.totalItems2}条，当前${(this.pageNumber2 - 1) * this.request2.maxResultCount + 1}-${this.pageNumber2 * this.request2.maxResultCount}条`;
            },
            /**
             * 分页
             */
            onChange2(page, pageSize) {
                this.pageNumber2 = page;
                this.request2.skipCount = (page - 1) * this.request2.maxResultCount;
                this.getAppDetailData();
            },
            showSizeChange2(current, size) {
                this.pageNumber2 = 1;
                this.request2.maxResultCount = size;
                this.getAppDetailData();
            },
            //排序
            sorterChange2(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request2.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request2.sorting = undefined;
                }
                this.getAppDetailData();
            },
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
